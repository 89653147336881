import { Button, Container, Dialog, DialogContent } from "@mui/material";
import bg from '../image/NewCompressed/remaining/login_bg.png'
import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import loginImg from '../image/NewCompressed/remaining/loginImg.png';
import playasmobile from '../image/NewCompressed/remaining/Play as mobile.png';
import loginButton from '../image/NewCompressed/remaining/loginUser.png';
import or from '../image/NewCompressed/remaining/or.png';
import guest from '../image/NewCompressed/remaining/play as guest.png';

const Loginpage = () => {
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qParam = queryParams.get('referal');

  console.log('userrefereal code is ',qParam);

  const handleLoginClick = () => {
    setOpenModal(true);
  };

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const RandomVal = async () => {
    try {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const length = Math.floor(Math.random() * 3) + 12; // Random length between 6 to 8
      let result = "";
      let reffralcode = generateRandomString();
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      // console.log(result);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}PlayasGuest`,
        {result,reffralcode},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data && response.data.token) {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        Cookies.set("token", response.data.token, {
          expires: expirationDate,
          path: "/"
        });
        // Cookies.get("token");
        navigate("/");
      }
    } catch (error) {
      toast.error("Network issue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const [formdata, setFormdata] = useState({
    username: "rocky",
    userphone: "",
    reffralcode: "",
    usereffral: "",
    userotp: 0
  });

  const handleChangeform = (e) => {
    const { name, value } = e.target;
    setFormdata((formdata) => ({
      ...formdata,
      [name]: value
    }));
  };

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = Math.floor(Math.random() * 3) + 8; // Random length between 6 to 8
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  const SendOtp = async (otp) => {
    console.log("Before sending OTP:", otp);
    console.log("Before sending OTP:", formdata.userphone);
    console.log(formdata.userphone,"formdata.userphon")
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}send-otp`, {
        userphone: formdata.userphone,
        userotp: otp
      });
      console.log(response,'response of OTP')

      if (response.data) {
        console.log("Data Sent Successfully", response.data);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const submitdata = async (e) => {
    console.log('submit button')
    const refrecodevalue = generateRandomString();
    // console.log(refrecodevalue);
    e.preventDefault();
    // Validate the entered number length
    // if (formdata.userphone.length < 10) {
    //   toast.error("Please enter a valid number", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true
    //   });
    //   return; // Prevent API call if validation fails
    // }

    if (formdata.userphone.length < 10){
      alert('Please enter a valid number');
      return;
    }

    try {
      const otp = Math.floor(1000 + Math.random() * 9000);
      const updatedFormData = {
        ...formdata,
        userotp: otp,
        reffralcode: refrecodevalue
      };
      setFormdata(updatedFormData);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}UserloginData`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      // console.log(response,'this is the response data',response.data.message)
      if (
        response.data &&
        response.data.message === "phone number already exists"
      ) {
        console.log('condition1',response.data.userphone)
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        Cookies.set("phone", response.data.userphone, {
          expires: expirationDate,
          path: "/"
        });

        // disptach(setToken(response.data.userphone));
        navigate("/otppage");
        await SendOtp(otp, response.data.userphone);
      } else if (response && response.data.message === "data save successfully") {
        console.log('condition2')
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        Cookies.set("phone", response.data.userphone, {
          expires: expirationDate,
          path: "/"
        });

        // disptach(setToken(response.data.userphone));
        navigate("/otppage");
        await SendOtp(otp, updatedFormData.userotp);
      } else {
        console.log('condition3')
        if (response.data.error) {
          console.log('gujkik',response.data.error)
          toast.error(response.data.error, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      }
    } catch (error) {
      //   console.error("Error submitting form:", error);
      if (error.response) {
        toast.error("Internal Server Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        console.error("Response data:", error.response.data.message);
      }
    }
  };



  return (
    <>
      <Container
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
       <div style={{ bottom: '3%', textAlign: "center", position: "absolute", color: "white", fontSize: '11px' }}>
  App Designed By <a target="_blank" href="https://www.maxwayinfotech.com/gaming/" style={{ color: '#a1bbe9' }}>MaxwayInfotech</a>
</div>

        <div style={{display:'flex',justifyContent:'flex-end'}}>
          <div style={{position:'relative',width:'50%',}}>
        <img src={loginImg} alt="" style={{ display:'block',width:'100%'}}/>
        <div style={{position:'absolute',top:'-4%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
        <img src={playasmobile} alt="" style={{width:'80%',display:'block',}} />
        </div>
        <div style={{position:'absolute',top:'32%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
        <input onChange={handleChangeform} type="number"name='userphone' placeholder="Enter phone number" style={{width:'50%',marginLeft:'11px',backgroundColor: '#d5bdd7',padding: '2px',borderRadius:' 6px',border: 'none',textAlign:'center'}} />
        </div>
        <div style={{position:'absolute',top:'40%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
        <input onChange={handleChangeform} type="text"name='usereffral' placeholder="Enter referal code" style={{width:'50%',marginLeft:'11px',backgroundColor: '#d5bdd7',padding: '2px',borderRadius:' 6px',border: 'none',textAlign:'center'}} />
        </div>
        <div style={{position:'absolute',top:'49%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
          <img onClick={submitdata} src={loginButton} alt=""style={{width:'55%',display:'block',marginLeft:'11px'}} />
        </div>
        <div style={{position:'absolute',top:'65%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
          <img src={or} alt=""style={{width:'55%',display:'block',marginLeft:'11px'}} />
        </div>
        <div style={{position:'absolute',bottom:'14%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
          <img onClick={RandomVal} src={guest} alt=""style={{width:'55%',display:'block',marginLeft:'11px'}} />
        </div>
        </div>
        </div>
       
      </Container>
    </>
  );
};

export default Loginpage;