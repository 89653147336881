import React, { useState,useEffect, useRef } from 'react';
import { Container } from '@mui/material';
import  './ProfileAvtar.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleButtonSound } from '../../RaectRedux/ActionApiOfData';


const ProfileAvtar = ({onClose,setAvtar}) => {
  const dispatch = useDispatch();
  const images= useSelector((state) => state.add.api8Data);
  const [slectedAvtar , setSlectedAvtar] = useState(null);
  const isInitialRender = useRef(true);
  useEffect(() => {
    if(isInitialRender.current){
      isInitialRender.current = false;
      return;
    }
   
    setAvtar(slectedAvtar);
    onClose();
  }, [slectedAvtar]);

  function handleAvtarChange(src) {
    setSlectedAvtar(src);
  }

  return (
    <Container style={{backgroundColor:'brown',padding:'5px',position:'relative',top:'50%',transform:'translateY(-50%)',borderRadius:'15px'}}>
        <div className='profile-avtar-header'>
            <img className='profile-avtar-main' src={images[24]} alt="" />
            <p>Choose an Avatar</p>
            <p></p>
        </div>
        <div className='profile-avtar-bg1' >
            <div className='profile-avtar-container'>
                <img className='profile-avtar-child' onClick={()=>{handleAvtarChange(images[91]);dispatch(handleButtonSound());}} src={images[91]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[92]);dispatch(handleButtonSound());}} src={images[92]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[93]);dispatch(handleButtonSound());}} src={images[93]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[94]);dispatch(handleButtonSound());}} src={images[94]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[112]);dispatch(handleButtonSound());}} src={images[112]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[113]);dispatch(handleButtonSound());}} src={images[113]} alt="" />
            </div>
            <div className='profile-avtar-container'>
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[114]);dispatch(handleButtonSound());}} src={images[114]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[115]);dispatch(handleButtonSound());}} src={images[115]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[116]);dispatch(handleButtonSound());}} src={images[116]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[117]);dispatch(handleButtonSound());}} src={images[117]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[118]);dispatch(handleButtonSound());}} src={images[118]} alt="" />
                <img className='profile-avtar-child'onClick={()=>{handleAvtarChange(images[119]);dispatch(handleButtonSound());}} src={images[119]} alt="" />
            </div>
        </div>
        <Link to="">
            <img
              onClick={()=>{onClose();dispatch(handleButtonSound());}}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                color: "white",
                width:'27px'
              }}
              src={images[40]}
            />
          </Link>
    </Container>
  )
}

export default ProfileAvtar