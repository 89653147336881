import axios from "axios";
import buttonClickSound from '../image/music/buttonSound.mpeg';
import { useSelector } from "react-redux";

// Action creator function to fetch data from Coingecko API
// Action creator function to fetch data from Coingecko API
export const SET_TOKEN = "SET_TOKEN";

export const setToken = (userphoneidentity) => ({
  type: SET_TOKEN,
  payload: userphoneidentity
});
export const fetchData = (id) => {
  return async (dispatch) => {
    try {
      // Check if id is defined before making the API call
     
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}UserPersonalFetchData/${id}`  
      );
      console.log(response, "bycrypt");
      dispatch({
        type: "FETCH_API1_DATA_SUCCESS",
        payload: response.data
      });
      console.log(response, "response");
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_API1_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const UpiDeatilsOfUserget = (id) => {
  // console.log(id, "id");
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}UpiDeatilsOfUserget/${id}`
      );

      // console.log(response.data, "UpiDeatilsOfUserget");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_UPIDETAILS_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_UPIDETAILS_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const BankDeatilsOfUserget = (id) => {
  console.log(id, "id");
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(
       `${process.env.REACT_APP_BASE_URL}BankDeatilsOfUserGet/${id}`
      );

      console.log(response.data, "UpiDeatilsOfUserget");
      // console.log(response.data, "Getalldatauser");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_BANKDETAILS_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_BANKDETAILS_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const getcarromControllerPrice = () => {
  return async (dispatch) => {
    try {
      // Send a GET request to the Coingecko API
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}GamePriceGet`);
      // console.log(response.data, "Getalldatauser");
      // Dispatch a success action with the fetched data
      dispatch({
        type: "FETCH_GAMELOBBY_API1_DATA_SUCCESS",
        payload: response.data
      });
    } catch (error) {
      // Dispatch a failure action if there is an error
      dispatch({
        type: "FETCH_GAMELOBBY_API1_DATA_FAILURE",
        payload: error.message
      });
    }
  };
};

export const gameData = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}GamePriceGetwithoutid`);
      console.log('this is the data',response.data)
      dispatch({
        type: "GameData",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "GameDataErr",
        payload: error.message
      });
    }
  };
}

export const BankDetails = (id)=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}BankDetailsgets/${id}`);
      console.log('this is the data',response.data)
      dispatch({
        type: "BankDetails",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "BankDetailsErr",
        payload: error.message
      });
    }
  };
}

export const UPIDetails = (id)=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}UpiDetailsgets/${id}`);
      console.log('this is the data',response.data)
      dispatch({
        type: "UPIDetails",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "UPIDetailsErr",
        payload: error.message
      });
    }
  };
}

export const loadedImg = (arr)=>{
  return (dispatch)=>{
    dispatch({
      type:'loadedImges',
      payload:arr
    })
  }
}

export const SliderImg  = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}getSliderimg`);
      console.log('this is the data',response.data)
      dispatch({
        type: "sliderImg",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "sliderImgErr",
        payload: error.message
      });
    }
  };
}

export const NoticeImg  = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}NoticeSliderget`);
      console.log('this is the data',response.data)
      dispatch({
        type: "NoticeImg",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "NoticeImgErr",
        payload: error.message
      });
    }
  };
}

export const storeImageDataItems = (arr)=>{
  return (dispatch)=>{
    dispatch({
      type:'storeImageDataItems',
      payload:arr
    })
  }
}

export const FestivalData  = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}festivalgetAlldata`);
      dispatch({
        type: "FestivlData",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "FestivlDataErr",
        payload: error.message
      });
    }
  };
}



export const PLAY_BUTTON_CLICK_SOUND = 'PLAY_BUTTON_CLICK_SOUND';

export const handleButtonSound = ()=>{
    const audio = new Audio(buttonClickSound);
    audio.play();
  
  return { type: PLAY_BUTTON_CLICK_SOUND };
}

export const GameHistory  = (id)=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}GameHistoryDataGet/${id}`);
      console.log('history data',response.data)
      dispatch({
        type: "gamehistory",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "gamehistoryErr",
        payload: error.message
      });
    }
  };
}

export const ReferWinData = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ReferWinDataGet`);
      console.log('this is the data',response.data)
      dispatch({
        type: "ReferWinData",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "ReferWinData",
        payload: error.message
      });
    }
  };
}

export const ReferEarnData = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ReferErnDataGet`);
      console.log('this is the data',response.data)
      dispatch({
        type: "ReferEarnData",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "ReferEarnData",
        payload: error.message
      });
    }
  };
}

export const ReferEarnMinimum = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ReferEarnFixedAmtgetData`);
      console.log('this is the data refer earn',response.data)
      dispatch({
        type: "ReferEarnMinimum",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "ReferEarnMinimumErr",
        payload: error.message
      });
    }
  };
}

export const ReferWinMinimum = ()=>{
  return async (dispatch) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}ReferWinDataFixedAmtgetdata`);
      console.log('this is the data',response.data)
      dispatch({
        type: "ReferWinMinimum",
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: "ReferWinMinimumErr",
        payload: error.message
      });
    }
  };
}

export const MusicControl = (val)=>{
  console.log(val,'value of play state')
  return async (dispatch) => {
      dispatch({
        type: "MusicControl",
        payload:val
      });
    } 
  };

  export const HomeLoadingControl = (val)=>{
    console.log(val,'value of play state')
    return async (dispatch) => {
        dispatch({
          type: "LobbyLoadingControl",
          payload:val
        });
      } 
    };

    // export const soundControl = (val)=>{
    //   console.log(val,'value of play state')
    //   return async (dispatch) => {
    //       dispatch({
    //         type: "SoundControl",
    //         payload:val
    //       });
    //     } 
    //   };

    export const RefreeData = (id)=>{
      return async (dispatch) => {
        try {
          //http://localhost:7000/refreeData/65dc3942af1454b3049e8871
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}refreeData/${id}`);
          console.log('this is the data',response.data)
          dispatch({
            type: "RefreeData",
            payload: response.data
          });
        } catch (error) {
          dispatch({
            type: "RefreeDataErr",
            payload: error.message
          });
        }
      };
    }
    

