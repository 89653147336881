import { configureStore} from '@reduxjs/toolkit';
import { dataReducer } from "./Reducer";

const store = configureStore({
  reducer: {
    add: dataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;


