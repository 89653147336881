import React, { useEffect, useState } from "react";
// import settingbg from "../../image/icons/graphics (1)/settingimg.png";
import {
  Container,
  Dialog,
  DialogContent,
  Grid
  // Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import './UserProfile.css';
import ProfileAvtar from "./ProfileAvtar";
import Innerpopup from "../Bonus/Innerpopup";
import Binding from "../Binding/Binding";
import { useDispatch, useSelector } from "react-redux";
import EditName from "./EditName";
import { handleButtonSound } from "../../RaectRedux/ActionApiOfData";

const User = ({ onClose,setAvtar,slectedImg}) => {
  const images= useSelector((state) => state.add.api8Data);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [selectedButtonType, setSelectedButtonType] = useState(null);
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedButtonType(null); // Reset selectedButtonType when closing the modal
  };

  const handleButtonClick = (buttonType) => {
    setOpenModal(true);
    setSelectedButtonType(buttonType);
  };
  const DialogContental = () => {
    switch (selectedButtonType) {
      case "inner":
        return <Innerpopup onClose={handleCloseModal} />;
        case "profileAvtar":
          return <ProfileAvtar style={{overflow:'hidden'}} onClose={handleCloseModal} setAvtar={setAvtar} />;  
          case "bound":
        return <Binding onClose={handleCloseModal} />;
        case "editName":
          return <EditName onClose={handleCloseModal} />;
      default:
        return null;
    }
  };
  return (
    <>
      {/* {loaded ? ( */}
        <Container
          style={{
            width: "100%",
            height: "90%",
            paddingLeft: "0px",
            paddingRight: "0px",
            display:'flex',
            alignItems:'center',
            maxHeight:"350px",
            maxWidth:'560px',
            position:'relative',
            top:'50%',
            transform:"translateY(-50%)"
          }}
        >
          <Grid container style={{ position: "relative" }}>
            <Grid
              xs={3}
              style={{
                backgroundImage: `url(${images[25]})`, // Specify the path to your image
                backgroundSize: "100% 95%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "220px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                textAlign: "center"
              }}
              className="left-right-height"
              // style={{
              //   backgroundColor: "#3f0202eb",
              //   height: "260px",
              //   borderBottomLeftRadius: "10px",
              //   borderTopLeftRadius: "10px"
              // }}
            >
              <img
                src={slectedImg?slectedImg:images[24]}
                alt=""
                style={{ width: "60%", display: "block", margin: "50px auto" }}
                onClick={() => {
                  handleButtonClick("profileAvtar");
                  dispatch(handleButtonSound());
                }}
              />
            </Grid>
            <Grid
              xs={9}
              style={{
                backgroundImage: `url(${images[26]})`, // Specify the path to your image
                backgroundSize: "100% 95%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "95%",
                height: "220px",
                paddingLeft: "0px",
                paddingRight: "0px",
                overflow: "auto",
                paddingTop: "0px",
                position: "relative",
                textAlign: "center"
              }}
              className="left-right-height"
              // style={{
              //   backgroundColor: "#5d0101eb",
              //   height: "260px",
              //   borderTopRightRadius: "10px",
              //   borderBottomRightRadius: "10px",
              //   position: "relative",
              //   paddingTop: "50px"
              // }}
            >
              <div
                style={{
                  margin: "0px auto",
                  textAlign: "center",
                  padding: "0px",
                  width: "90%",
                  borderRadius: "10px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "55%",
                  transform: "translateY(-50%)",
                  
                }}
              >
                <img src={images[65]} alt="" style={{ width: "100%",height:'160px' }} />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    textAlign: "center",
                    margin: "auto"
                  }}
                >
                  <div style={{ margin: "auto 10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    ></span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Name
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <Link onClick={()=>{handleButtonClick("editName");dispatch(handleButtonSound());}} >
                        <img src={images[32]} style={{ width: "20px" }} alt="" />
                      </Link>
                    </span>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img src={images[63]} style={{ width: "20px" }} alt="" />
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Capital
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    ></span>
                  </div>

                  <div style={{ margin: "10px" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img src={images[63]} style={{ width: "20px" }} alt="" />
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        width: "80px",
                        textAlign: "center",
                        color: "yellow"
                      }}
                    >
                      Bonus
                    </span>
                    <p
                      style={{
                        width: "100px",
                        backgroundColor: "#130101",
                        color: "brown",
                        fontSize: "14px",
                        borderRadius: "10px",
                        textAlign: "center",
                        display: "inline-block",
                        margin: "0",
                        padding: "0"
                      }}
                    >
                      Guest143829
                    </p>
                    <span
                      style={{
                        display: "inline-block",
                        width: "50px",
                        textAlign: "center"
                      }}
                    >
                      <img
                        src={images[32]}
                        style={{ width: "20px" }}
                        alt=""
                        onClick={() => {
                          handleButtonClick("inner");
                          dispatch(handleButtonSound());
                        }}
                      />
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {/* <p
                      className="user-bound1"
                      style={{
                       backgroundImage:`url(${bound})`,
                       backgroundPosition:'center',
                       backgroundRepeat:'no-repeat',
                       backgroundSize:'contain',
                       padding:'1px',
                       width:'70px',
                       margin:'0'
                      }}
                    > */}

                    <img src={images[88]} alt="" style={{
                      display:'block',margin:'auto',width:'70px'
                    }}  onClick={() => {
                      handleButtonClick("bound");
                      dispatch(handleButtonSound());
                    }} />
                     
                    {/* </p> */}
                  </div>
                </div>
              </div>

              {/* <p style={{ textAlign: 'center', color: 'yellow', margin: '0px', padding: '0px' }}>Binding</p> */}
            </Grid>
            <Grid xs={12} style={{ position: "absolute", width: "100%" }}>
              <div style={{ position: "relative" }}>
                <img src={images[33]} style={{ width: "100%" }} alt="" />
                <Link>
                  <img
                    onClick={()=>{onClose();dispatch(handleButtonSound());}}
                    style={{
                      position: "absolute",
                      right: "7px",
                      color: "white",
                      top: "5px",
                      width:'23px'
                    }}
                    src={images[40]}
                  />
                </Link>
              </div>
            </Grid>
          </Grid>
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                width: "100%",
                boxShadow: "none",
                height: "100%",
                paddingBottom: "0",
                overflow:'hidden'
              }
            }}
          >
            <DialogContent style={{overflow:'hidden'}}>{DialogContental()}</DialogContent>
          </Dialog>
        </Container>
      {/* ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Adjust the height as needed
            textAlign: "center",
            color: "#bd5c0c"
          }}
        >
          <SyncLoader size={12} color={"#bd5c0c"} />
        </div>
      )} */}
    </>
  );
};

export default User;
