import React, { useState } from "react";
import './UserProfile.css';
import { ToastContainer } from "react-toastify";
import { Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchData, handleButtonSound } from "../../RaectRedux/ActionApiOfData";
// import login from "../../image/icons/RANK/RANK/13.webp";
// import Buy from "../Shop_Buy/Buy";
// import login from "../image/icons/RANK/RANK/13.webp";

const EditName = ({onClose}) => {
  const images = useSelector((state)=>state.add.api8Data);
  const AllData = useSelector((state)=>state.add.api1Data);
  const dispatch = useDispatch();

  const [formdata,setFormdata] = useState({
    // id, username
    id:AllData._id,
    username:""
  })

  function handleNameChange(e){
    setFormdata({...formdata,[e.target.name]:e.target.value})
  }
  
async function handleSubmit(e){
  e.preventDefault()
const res = await axios.post(`${process.env.REACT_APP_BASE_URL}editname`,formdata);
if(res.status===200){
  setFormdata({
    id:AllData._id,
    username:""
  })
  dispatch(fetchData(AllData._id));
  onClose();
}
}

  return (
    <>
      <Container style={{display:'flex',alignItems:'center',position:'absolute',top:'42%',transform:'translateY(-50%'}}>
        <Grid
          container
          style={{
            // backgroundImage: `url(${loginIn})`,
            backgroundSize: "85% 100%",
            backgroundRepeat: "no-repeat",
            height: "250px",
            paddingTop: "24px",
            // marginTop: "30px",
            backgroundPosition: "center center",
            position:'relative'
          }}
          className="editName1"
        >
          <img src={images[40]} style={{position:'absolute',top:'11%',right:'13%',width:'24px'}}  onClick={()=>{onClose();dispatch(handleButtonSound());}} alt="" />
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <div className="main_form_start">
              <form
                onSubmit={handleSubmit}
                style={{ marginTop: "12px",padding:'1px',backgroundColor:'rgb(102, 38, 38)',borderRadius:"15px" }}
                className="editUser-form"
              >
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "0px",
                    color: "#ffc7bf",
                    marginBottom: "15px",
                    fontWeight: "bold",
                    marginLeft:'auto',
                    marginRight:'auto',
                  }}
                >
                  Edit Name.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "12px",
                    color: "#918f8d",
                    marginTop: "10px",
                    marginBottom: "0px",
                    display:'inline-block',
                    width:'30%',
                    textAlign:'center'
                  }}
                >
                  <label
                    htmlFor=""
                    style={{ fontSize: "15px", color: "#f1e2e0" }}
                  >
                    Edit Name
                  </label>
                </p>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    paddingTop: "0px",
                    paddingBottum: "0px",
                    color: "#ffc7bf",
                    display:'inline-block',
                    textAlign:'center',
                    width:"60%",
                    maxWidth:'350px'
                  }}
                >
                  <input
                    style={{
                      width: "94%",
                      color: "#918f8d",
                      margin: "0% 2%",
                      padding: "5px 5px",
                      borderRadius: "5px",
                      backgroundColor: "#331115"
                    }}
                    type="text"
                    name="username"
                    // onChange={handleChangeform}
                    placeholder="Enter Number"
                    // value={formdata.userphone}
                    onChange = {handleNameChange}
                    value = {FormData.username}
                  />
                </p>

                <p style={{ marginLeft:'auto',
                    marginRight:'auto',}}>
                  <button
                    style={{
                      backgroundColor: "green",
                      color: "azure",
                      width: "130px",
                      justifyContent: "center",
                      margin: "auto",
                      borderRadius: "6px",
                      padding: "7px 5px",
                      display:'block'
                    }}
                  type="submit"
                  >
                    Edit Name
                  </button>
                </p>
              </form>
            </div>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <ToastContainer />
      </Container>
      <ToastContainer />
    </>
  );
};

export default EditName;
