import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import img from "../src/image/icons/popup-bg.jpg";
import "./App.css";
import './RaectRedux/ImagePop.css'
import OtpPage from "./component/OtpPage";
import User from "./component/User_Profile/User";
import Withdraw from "./component/withdraw/Withdraw";
import support from "./image/NewCompressed/Lobby Webp/bg_main.png";
import PrivateRoute from "./component/PrivateRoute";
import Innerpopup from "./component/Bonus/Innerpopup";
import LoginPage from "./component/Loginpage";
import Gamelobby from "./component/Gamelobby";
import EditName from "./component/User_Profile/EditName";
import { SyncLoader } from "react-spinners";
import Cricket from "./component/Cricket.jsx";
import Joker from './component/Joker.jsx'
import ZombieLand from "./component/ZombieLand.jsx";
import Aflatoon from "./component/Aflatoon.jsx";
import Ak from "./component/AK47.jsx";
import Muflis from "./component/Mufils.jsx";
import Teenpatti from "./component/TeenPatti.jsx";
import TeenPattiGold from "./component/TeenPattiGold.jsx";
import Zandu from "./component/Zandu.jsx";
import Vegas from "./component/Vegas.jsx";
import Fourcards from "./component/FourCards.jsx";
import Fourboot from "./component/FourBoot.jsx";
import Twoboot from "./component/TwoBoot.jsx";
import Explorer from "./component/Explorer.jsx";
import Sevenslot from "./component/Sevenslot.jsx";
import Rummy from "./component/Rummy.jsx";
import PoolRummy from "./component/PoolRummy.jsx";
import DealRummy from "./component/DealRummy.jsx";
import PointRummy from "./component/PiontRummy.jsx";
import LuckyFarm from "./component/LuckyFarm.jsx";
import Fruits from "./component/Fruits.jsx";
import Zeus from "./component/Zeus.jsx";
import Safari from "./component/Safari.jsx";
import AndarBahar from "./component/AndarBahar.jsx";
import Dragontiger from "./component/Dragontiger.jsx";
import Roullete from "./component/Roullete.jsx";
import KingvsQueen from "./component/KingvsQueen.jsx";
import RedvsBlack from "./component/RedvsBlack.jsx";

const Home = lazy(() => import("./component/Home.jsx"));

function App() {
  const handleContextMenu = (e) => {
    e.preventDefault();
    return false;
  };
  return (
    <div className="app-container" onContextMenu={handleContextMenu}>
      <div
        className="background-image"
        style={{
          backgroundImage: `url(${support})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          width: "100%"
        }}
      >
        <div className="overlay"></div>
        <div className="component-container">
          <Router>
            <Routes>
              <Route path="/" exact element={<PrivateRoute />}>
                <Route
                  index
                  element={
                    <Suspense
                      fallback={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh", // Adjust the height as needed
                            textAlign: "center",
                            color: "#bd5c0c"
                          }}
                        >
                          <SyncLoader size={12} color={"#bd5c0c"} />
                        </div>
                      }
                    >
                      <Home />
                    </Suspense>
                  }
                />
                <Route path="RedvsBlack" element={<RedvsBlack/>} />
                <Route path="KingVsQueen" element={<KingvsQueen/>} />
                <Route path="roullete" element={<Roullete/>} />
                <Route path="dragontiger" element={<Dragontiger/>} />
                <Route path="AndarBahar" element={<AndarBahar/>} />
                <Route path="Safari" element={<Safari/>} />
                <Route path="Zeus" element={<Zeus/>} />
                <Route path="Fruits" element={<Fruits/>} />
                <Route path="LuckyFarm" element={<LuckyFarm/>} />
                <Route path="PointRummy" element={<PointRummy/>} />
                <Route path="DealRummy" element={<DealRummy/>} />
                <Route path="PoolRummy" element={<PoolRummy/>} />
                <Route path="Rummy" element={<Rummy/>} />
                <Route path="7Slot" element={<Sevenslot/>} />
                <Route path="explorer" element={<Explorer/>} />
                <Route path="2boot" element={<Twoboot/>} />
                <Route path="4boot" element={<Fourboot/>} />
                <Route path="4cards" element={<Fourcards/>} />
                <Route path="Vegas" element={<Vegas/>} />
                <Route path="Zandu" element={<Zandu/>} />
                <Route path="TeenPattiGold" element={<TeenPattiGold/>} />
                <Route path="TeenPatti" element={<Teenpatti/>} />
                <Route path="muflis" element={<Muflis/>} />
                <Route path="ak47" element={<Ak/>} />
                <Route path="aflatoon" element={<Aflatoon/>} />
                <Route path="zombieland" element={<ZombieLand />} />
                <Route path="joker" element={<Joker />} />
                <Route path="cricket" exact element={<Cricket />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="Game" element={<Gamelobby />} />

              </Route>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/otppage" element={<OtpPage />} />
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
