import React from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";


const Innerpopup = ({ onClose }) => {
  const images= useSelector((state) => state.add.api8Data);
  return (
    <Container
      style={{
        width: "80%",
        backgroundColor:'brown',
        padding:'0',
        borderRadius:'12px'
      }}
    >
      <div style={{ position: "relative" }}>
        {/* <img src={bg_img} width={"100%"} style={{}} alt="" /> */}
        <div style={{ textAlign: "center",backgroundImage:`url(${images[85]})`,backgroundPosition:'center',backgroundSize:'100%',margin:'10px',backgroundRepeat:'no-repeat',padding:'14px' }}>
          <p
            style={{
            
              color: "white"
            }}
          >
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Optio
            numquam error laboriosam maiores est dicta ratione, natus quos
            tenetur, iure ab tempora officia temporibus quae labore nihil.
            Voluptates, dicta odio.
          </p>
        </div>
      </div>
      <img  onClick={onClose} src={images[86]} alt="" style={{width:'100px',display:'block',
    margin:'auto'}} />
    </Container>
  );
};

export default Innerpopup;
