import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleButtonSound } from '../../RaectRedux/ActionApiOfData';

const Rules = ({onClose}) => {
    const images = useSelector((state)=>state.add.api8Data);
    const dispatch = useDispatch();
  return (
    <div style={{height:'90%',width:'90%',backgroundImage:`url(${images[144]})`,backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'100% 100%',maxWidth:'500px',margin:'auto',padding:'7%',textAlign:'justify',color:'white',position:'relative'}}>
        <div style={{position:'absolute',left:'50%',transform:'translateX(-50%)',top:'2%'}}>Withdraw Rules</div>
        <img
                    onClick={()=>{onClose();dispatch(handleButtonSound());}}
                    style={{
                      position: "absolute",
                      right: "-1%",
                      color: "white",
                      top: "-6%",
                      width:'23px'
                    }}
                    src={images[40]}
                  />
    <div style={{textAlign:'center',color:'#520b07eb'}}>Higher the VIP level , Higher the Bonus</div>
    <ul style={{margin:'5px'}}>
        <li>Withdrawal is applicable any time within 24 hours , and no charges.</li>
        <li>If the bank handles it smoothly , credit to the account within 3-5 Minutes.</li>
        <li>Please confirm that the bound withdrawal account information is correct.</li>
    </ul>
    </div>
  )
}

export default Rules