import { Container, Grid } from "@mui/material";
import React, { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import loginImg from '../image/NewCompressed/remaining/loginImg.png';
import bg from '../image/NewCompressed/remaining/login_bg.png';
import playasmobile from '../image/NewCompressed/remaining/Play as mobile.png';
const OtpPage = () => {
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [otp, setOtp] = useState(["", "", "", ""]);
  const navigate = useNavigate();
  const handleOtp = (index, value) => {
    // Update the OTP state when the user enters the OTP
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    const nextIndex = index + 1;
    if (nextIndex < 4 && value !== "" && !isNaN(value)) {
      inputRefs[nextIndex].current.focus();
    }
  };

  const handleKeyUp = (index, e) => {
    const key = e.key.toLowerCase();
    if (key === "backspace" || key === "delete") {
      // Clear the current input and move focus to the previous input
      handleOtp(index, "");
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        inputRefs[prevIndex].current.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phone = Cookies.get("phone");
    if (phone) {
      // Decode the JWT token
      const decoded = jwtDecode(phone);
      let userphone = decoded.userphone;
      alert(userphone,"userphoneuserphoneuserphoneuserphoneuserphoneuserphone")
      try {
        const enteredOtp = otp.join("");
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}updateotp`,
          JSON.stringify({ userphone, enteredOtp }),
          {
            headers: {
              "Content-Type":"application/json"
            }                                                                              
            
          }
        );

        if (res.data && res.data.token) {
          const expirationDate = new Date();
          expirationDate.setMonth(expirationDate.getMonth() + 1);
          Cookies.set("token", res.data.token, {
            expires: expirationDate,
            path: "/"
          });
          Cookies.remove("phone");
          navigate("/");
         
        } else {
          toast.error("Invalid userphone or OTP", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      } catch (error) {
        toast.error("Entered OTP is invalid", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } else {
    }
    setOtp(["", "", "", ""]);
  };
  return (
    <>
      <Container style={{backgroundImage:`url(${bg})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center',width:'100%',height:'100%',display:'flex',justifyContent:'flex-end'}}>
        <div style={{width:'50%',position:'relative'}}>
          <img src={loginImg} alt="" style={{width:'100%',textAlign:'center',}} />
          <div style={{position:'absolute',top:'-4%',width:'100%',textAlign:"center",textAlign:'-moz-center'}}>
        <img src={playasmobile} alt="" style={{width:'80%',display:'block',}} />
        </div>
        <form onSubmit={handleSubmit} style={{position:'absolute',top:'36%',marginLeft:'2%',textAlign:'center',width:'100%'}}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {Array.from({ length: 4 }, (_, index) => (
                    <input
                      key={index}
                      ref={inputRefs[index]}
                      style={{
                        width: "1.8rem",
                        height: "1.8rem",
                        margin: "3px",
                        textAlign: "center",
                        fontSize: "1rem"
                      }}
                      name={`otP${index}`}
                      type="text"
                      inputMode="numeric"
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => handleOtp(index, e.target.value)}
                      onKeyUp={(e) => handleKeyUp(index, e)}
                      tabIndex={index + 1}
                    />
                  ))}
                </div>
                <button
                  type="submit"
                  name="otp_ver"
                  style={{
                    margin: "20px",
                    backgroundColor: "green",
                    borderRadius: "6px",
                    padding: "5px 15px",
                    color: "#fff"
                  }}
                >
                  Submit
                </button>
              </form>
        </div>
        
        <ToastContainer />
        {/* <Grid
          container
          style={{
            marginTop: "25px",
            paddingTop: "10px",
            backgroundPosition: "center center"
          }}
        >
          <Grid xs={2}></Grid>
          <Grid xs={8}>
            <div style={{ marginTop: "10vh", textAlign: "center" }}>
              <p
                style={{
                  //   marginBottom: "0px",
                  //   marginTop: "0px",
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "bold"
                }}
              >
                OtpPage
              </p>
             
            </div>
          </Grid>
          <Grid xs={2}></Grid>
        </Grid> */}
      </Container>
    </>
  );
};

export default OtpPage;