import axios from "axios";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Unity, useUnityContext } from "react-unity-webgl";
import { HomeLoadingControl, MusicControl, fetchData } from "../RaectRedux/ActionApiOfData";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const Zandu = () => {
        const id = Cookies.get("token");
        const decoded = jwtDecode(id);
        console.log(decoded,'jwt')
          const [isGameOver, setIsGameOver] = useState(false);
          const [userName, setUserName] = useState(0);
          const [isGameStart,setIsGameStart] = useState(false);
          const [unityLoaded , setUnityLoaded] = useState(true);
          const dispatch = useDispatch();
          const nevigate = useNavigate();
          const images = useSelector((state)=>state.add.api8Data);

          useEffect(()=>{
            dispatch(fetchData(decoded._id));
          },[]);

          const AllData = useSelector((state)=>state.add.api1Data);
          console.log(AllData._id,'omprakashlokeshvikaram')
          const [score, setScore] = useState(AllData.wallet+AllData.TotalBonus+AllData.WinWallet);
          const { unityProvider, addEventListener, removeEventListener,sendMessage,isLoaded,loadingProgression} = useUnityContext({
            loaderUrl: "Zandu_build/Build/Zandu_build.loader.js",
            dataUrl: "Zandu_build/Build/Zandu_build.data",
            frameworkUrl: "Zandu_build/Build/Zandu_build.framework.js",
            codeUrl: "Zandu_build/Build/Zandu_build.wasm",
          });
      
          useEffect(()=>{
          if(isGameStart){
            sendMessage("ConnectionManager", "SetWalletAmouont",parseFloat((AllData.wallet+AllData.TotalBonus+AllData.WinWallet).toFixed(2)));
          }
          },[isGameStart]);
      
          useEffect(()=>{
            if(isGameOver){
              dispatch(MusicControl(true));
              dispatch(HomeLoadingControl(false));
             setTimeout(()=>{
              nevigate('/');
             },1000)
            }
      
          },[isGameOver])
        
        
          // function handleClickSpawnEnemies() {
          //   sendMessage("ConnectionGameObject", "SetWalletAmouont", 500.00);
          //  }
          useEffect(() => {
            const fetchDataAndUpdateUnity = async () => {
              // Fetching data from the server...
              try {
                const totalAmount = AllData.wallet + AllData.TotalBonus + AllData.WinWallet;
                console.log('this is the score',score);
                console.log('total amount',totalAmount);
                      const rem = score - totalAmount;
                      let win = 0;
                      let loss = 0;
                      let status = 0;
                      let newWallet = AllData.wallet;
                      let newBonus = AllData.TotalBonus;
                      let WinWallet = AllData.WinWallet;
                      if (rem >= 0) {
                        win = rem;
                        status = 1;
                        WinWallet = WinWallet + win;
                      }
      
                      else{
                        loss = -rem;
                        status = 2;
                         newWallet = newWallet + rem ;
                         if(newWallet<0){
                          newBonus = newBonus + newWallet;
                          newWallet = 0;
                         }
                         if(newBonus<0){
                          WinWallet = WinWallet + newBonus;
                          newBonus = 0;
                         }
                      }
                      const formData = {
                        gameName: 'Zandu',
                        userId: AllData._id,
                        NewWallet: newWallet,
                        TotalBonus: newBonus,
                        TotalWin: win,
                        TotalLoss: loss,
                        status:status,
                        WinWallet:WinWallet
                      }
                const res = await axios.post(`${process.env.REACT_APP_BASE_URL}GameHistoryData`, formData);
                console.log(res, 'gtyhh')
                if (res.status === 200) {
                  dispatch(fetchData(AllData._id));
                }
              } catch (error) {
                console.error("Error fetching data and updating Unity:", error);
              }
            };
        
            if (score !== AllData.wallet + AllData.TotalBonus) {
              fetchDataAndUpdateUnity();
            }
          }, [score, AllData.wallet, AllData.TotalBonus, dispatch]);
        
           const handleGameChange = useCallback(async (score) => {
            console.log(score ,"kopoikoo")
            setScore(score);
          }, []);
      
          const handleGameStart = useCallback((val)=>{
           console.log('gamestart',true);
            setIsGameStart(true);
          },[])
      
          const handleGameOver = useCallback((val)=>{
            console.log('gameover',true);
             setIsGameOver(val);
           },[])
        
          useEffect(() => {
            addEventListener('GameStatus',handleGameStart);
            addEventListener("WalletChangeHandle", handleGameChange);
            addEventListener("GameStatusExit", handleGameOver);
            return () => {
            //   removeEventListener('GameStatus',handleGameStart);
              removeEventListener("WalletChangeHandle", handleGameChange);
              removeEventListener("GameStatusExit", handleGameOver);
              setUnityLoaded(false);
            };
          }, [addEventListener, removeEventListener,handleGameStart,handleGameChange,handleGameOver]);
        
          const [showImg , setShowImg] = useState(false);
          useEffect(()=>{
            if(isLoaded){
              setShowImg(true);
             setTimeout(()=>{
              setShowImg(false)
             },1500)
            
            }
          },[isLoaded])
        
          return (
            <Fragment style={{position:"relative"}}>
              
      {!isLoaded && (
        <div class="progress-bar blue stripes" style={{width:'300px',backgroundColor:'white',borderRadius:'30px',textAlign:'left',position:'relative',top:'70%',margin:'auto'}}>
        <span style={{width:`${Math.round(loadingProgression * 100)}%`,display:'block',height:'20px',borderRadius:'30px',textAlign:'center'}}>{Math.round(loadingProgression * 100)}%</span>
      </div>
      )}
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={2}
        style={{ visibility: isLoaded ? "visible" : "hidden", width:'100vw',height:'100vh'}}
      />
      {showImg && <img src={images[159]} style={{position:'absolute',top:'0',left:"0",width:'100vw',height:"100%"}} alt="Your Image" />}
    </Fragment>
        );
  }
  

export default Zandu;