import React, { useEffect, useState } from "react";
import styless from "./Designer.module.css";
import { Button, Container, Grid, ListItem, ListItemText,Dialog,
  DialogContent,} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import './withdraw.css';
import axios from "axios";
import {
  BankDeatilsOfUserget,
  BankDetails,
  GameHistory,
  UPIDetails,
  UpiDeatilsOfUserget,
  fetchData,
  handleButtonSound
} from "../../RaectRedux/ActionApiOfData";
import { Link } from "react-router-dom";
import Rules from "./Rules";

const Withdraw = ({onClose}) => {
  const images= useSelector((state) => state.add.api8Data);
  const [selectedOption, setSelectedOption] = useState("");
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const AllData = useSelector((state) => state.add.api1Data);
  const [openModal, setOpenModal] = useState(false);
  const [selectedButtonType, setSelectedButtonType] = useState(null);
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedButtonType(null); // Reset selectedButtonType when closing the modal
  };

  const handleButtonClick = (buttonType) => {
    setOpenModal(true);
    setSelectedButtonType(buttonType);
  };

  const DialogContental = () => {
    switch (selectedButtonType) {
      case "rules":
        return <Rules onClose={handleCloseModal} />;

      default:
        return null;
    }
  };
  

  const dispatch = useDispatch();
  const id = AllData._id;
  useEffect(() => {
    dispatch(BankDetails(id));
    dispatch(UPIDetails(id))

  }, [dispatch]);

  const bankdetails = useSelector((state) => state.add.api6Data);
  const upidetails = useSelector((state) => state.add.api7Data);

  const [formdata, setFormdata] = useState({
    holdername: "",
    upiaddress: "",
    data: new Date().toISOString()
  });

  let withdrowable = AllData.WinWallet;
  let totalAmount = AllData.TotalBonus+withdrowable;
  let accountNo = bankdetails.AccountNumber;
  let upi = upidetails.UpiAddress;
  
  let [displaytype,setDisplayType] = useState(null);
 

  const handleAmountChange = (event) => {
    const value = event.target.value;
    console.log("New value:", value);
    setWithdrawalAmount(value);
  };
  
async function handleWithdraw(e){
  e.preventDefault();
  
  if(withdrawalAmount>withdrowable){
    toast.error("Insufficient Amount", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
  else if(withdrawalAmount<50){
    toast.error("Amount must be greater than 50", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  }
  else{
    const withdrawData = {
      Amount:withdrawalAmount,
      uniqeId:AllData._id,
      PhoneNumber:AllData.userphone,
      UserName:AllData.username,
      UpiAddress:selectedOption=='upi'?upidetails.UpiAddress:'',
      AccountNumber:selectedOption=='bank'?bankdetails.AccountNumber:undefined,
      IFSC:selectedOption=='bank'?bankdetails.IFSC:undefined,
      BankName:selectedOption=='bank'?bankdetails.BankName:undefined,
      Email:selectedOption=='bank'?bankdetails.Email:undefined,
    }
    
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}withdraw`,withdrawData);
    if(res.status==200){
      setWithdrawalAmount('');
      dispatch(fetchData(AllData._id))
      toast.success("Request sent , Your amount will be transfered within three working days", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }
 }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const [formdatabank, setformdatabank] = useState({
    holderNameuser: "",
    ifscCode: "",
    bankName: "",
    email: "",
    accountNumber: "",
    data: new Date().toISOString()
  });
  const handleChangebank = (e) => {
    const { name, value } = e.target;
    setformdatabank((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
  
function handleOptionChange(e){
const val  = e.target.value;
setSelectedOption(val);
if(val==='bank'){
  if(!accountNo){
    setDisplayType('')
    toast.error("please add bank details", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
    setWithdrawalAmount('')
    setSelectedOption('')
  }
  else{
    setDisplayType(accountNo);
  }
 
}
if(val==='upi'){
  if(!upi){
    setDisplayType('')
    toast.error("please add UPI details", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
    setWithdrawalAmount('')
    setSelectedOption('')
  }
  else{
    setDisplayType(upi);
  }
  
}
}

async function handleUpiSubmit(e){
  e.preventDefault();
  const upiData = {
    UserName:formdata.holdername,
    PhoneNumber:AllData.userphone,
    UpiAddress:formdata.upiaddress,
    UniqeId:AllData._id
  }
  console.log('acclnk',upiData);
const res1 = await axios.post(`${process.env.REACT_APP_BASE_URL}UpiDetails`,upiData);
console.log(res1)
if(res1.status==200){
 setFormdata({
    holdername: "",
    upiaddress: "",
    data: new Date().toISOString()
  })
  toast.success("UPI added successfully", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
  dispatch(UPIDetails(id));
}
else{
  toast.error("Please try again", {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}
}

async function handleBankSubmit(e){
  e.preventDefault();
  const bankData = {
    AccountNumber:Number(formdatabank.accountNumber),
    UserName:formdatabank.holderNameuser,
    BankName:formdatabank.bankName,
    IFSC:formdatabank.ifscCode,
    Email:formdatabank.email,
    UniqeId:AllData._id
  }
  // AccountNumber, UserName, IFSC, BankName, Email, UniqeId
  console.log('bank data',bankData)
  const res2 = await axios.post(`${process.env.REACT_APP_BASE_URL}BankDetails`,bankData);
  if(res2.status==200){
    setformdatabank({
      holderNameuser: "",
      ifscCode: "",
      bankName: "",
      email: "",
      accountNumber: "",
      data: new Date().toISOString()
     })
     toast.success("UPI added successfully", {
       position: "top-center",
       autoClose: 3000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true
     });
     dispatch(BankDetails(id));
   }
   else{
     toast.error("Please try again", {
       position: "top-center",
       autoClose: 3000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true
     });
   }
}

const otherHistory = useSelector((state)=>state.add.api13Data);
console.log('gamehistroydata',otherHistory)

  const obj = {
    withdraw: "withdraw",
    UPI: "UPI",
    Bank: "Bank",
    History: "History"
  };

  const obj1 = {
    Recharge: "Recharge",
    withdrawHistory: "withdrawHistory",
    Other: "Other"
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const [activeSectionKeysec, setActiveSectionKeysec] = useState(
    Object.keys(obj1)[0]
  );
  const genratebody2 = () => {
    switch (obj1[activeSectionKeysec]) {
      case obj1.Recharge:
        return (
          <>
            <div
              className="table"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%", color: "#dd6800" }}>Date</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Amount</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Bonus</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Status</th>

                  </tr>
                </thead>
                <tbody>
                  {AllData.PaymentHistoryData.map((item)=>(
                  <tr>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {formatDate(item.Date)}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.txnAmount}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.percentageVal}
                    </td>

                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.status==0?'Panding':item.status==1?'Successful':'Rejected'}
                    </td>
                    
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case obj1.withdrawHistory:
        return (
          <>
            <div
              className="table"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%", color: "#dd6800" }}>UserName</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Amount</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {AllData.withdraw.map((item)=>(
                  <tr>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {AllData.username}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.Amount}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.status==0?'Panding':'Approved'}
                    </td>
                    
                  </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        );
      case obj1.Other:
        return (
          <div className="history1" style={{maxHeight:"190px"}}>
             <table style={{ width: "100%", tableLayout: "fixed" }}>
                <thead>
                  <tr>
                    <th style={{ width: "20%", color: "#dd6800" }}>GameName</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Status</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Loss</th>
                    <th style={{ width: "20%", color: "#dd6800" }}>Win</th>

                  </tr>
                </thead>
                <tbody>
                  {otherHistory.gameHistory.length>0?otherHistory.gameHistory.filter((item)=>item.status!=0).map((item)=>

                 
                  <tr>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.gameName}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.status==1?'Win':'Loss'}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.TotalLoss}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "center",
                        color: "#cd8264"
                      }}
                    >
                      {item.TotalWin}
                    </td>
                   
                  </tr>
                   ):''}
                  </tbody>
                </table>
                    </div>
        );
      default:
        return null;
    }
  };
  const [activeSectionKey, setActiveSectionKey] = useState(Object.keys(obj)[0]);
  const genratebody1 = () => {
    switch (obj[activeSectionKey]) {
      case obj.History:
        return (
          <Container  style={{ backgroundColor:'rgb(91, 34, 34)',height:'100%'}}>
            <Grid container >
              {Object.keys(obj1)?.map((item) => (
                <Grid
                  xs={4}
                  style={{
                    marginTop: "1px",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#e35b04",
                    padding:'7px',
                    paddingBottom:'14px'
                  }} // Set your desired font size, e.g., "16px"
                  key={item}
                  className={`${styless.section1} ${
                    activeSectionKeysec === item ? styless.activee1 : ""
                  }`}
                  onClick={() => {setActiveSectionKeysec(item);dispatch(handleButtonSound());}}
                >
                  <span
                    style={{
                      fontSize: "14px",
                      color: "#ff7937",
                      fontWeight: "bold"
                    }}
                  >
                    {item}
                  </span>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                style={{
                  height: "160px",
                  overflow: "auto",
                }}
                className="history2"
              >
                {genratebody2()}
              </Grid>
            </Grid>
          </Container>
        );
      case obj.UPI:
        return (
          <Container
          style={{
         overflow:'hidden',
         textAlign:'center',
         backgroundColor:'rgb(91, 34, 34)',
         height:'100%'

          }}
          >
            <Grid
              container
              style={{
               paddingTop:'20px',
                width: "100%",
                height: "100%",
                position:'relative',
                left:'50%',
                transform:'translateX(-50%)',
                paddingBottom:'20px',
               
              }}
              className="UPI1"
            >
              <Grid
                xs={12}
                style={{
                  backgroundRepeat: "no-repeat",
                  // paddingTop: "30px",
                  backgroundPosition: "center"
                }}
              >
                {/* handleFormSubmit */}
                <form style={{paddingTop:'6px',width:'100%'}} onSubmit={handleUpiSubmit}>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "15px",
                      color: "#cd8264"
                    }}
                  >
                    <span style={{ fontSize: "14px", paddingRight: "5px" ,display:'inline-block',width:"90px"}}>
                      UPI address
                    </span>
                    <input
                      placeholder="Upi Address"
                      type="text"
                      name="upiaddress"
                      value={formdata.upiaddress}
                      onChange={handleChange}
                      required
                      style={{
                        borderRadius: "10px",
                        color: "white",
                        textAlign: "center",
                        padding: "2px 5px",
                        backgroundColor: "#310e0e"
                      }}
                    />
                  </p>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "15px",
                      color: "#cd8264"
                    }}
                  >
                    <span style={{ fontSize: "14px", paddingRight: "5px" ,display:'inline-block',width:"90px"}}>
                      Holder Name
                    </span>
                    <input
                      placeholder="Upi Address"
                      type="text"
                      name="holdername"
                      value={formdata.holdername}
                      onChange={handleChange}
                      required
                      style={{
                        borderRadius: "10px",
                        color: "white",
                        textAlign: "center",
                        padding: "2px 5px",
                        backgroundColor: "#310e0e"
                      }}
                    />
                  </p>
                  <Button
                    type="submit"
                    style={{
                      margin:'28px auto 0 auto',
                      fontSize: "15px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      padding: "14px",
                      color: "white",
                      backgroundImage: `url(${images[2]})`,
                      backgroundSize: 'contain',
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Container>
        ); // if key is "info" then return "personInfo object"
      case obj.withdraw:
        return (
          // handleSubmit
          <form style={{height:'100%', backgroundColor: '#5b2222'}} onSubmit={handleWithdraw}>
            <Grid container>
              <Grid
                xs={12}
                style={{
                  backgroundImage: `url(${images[11]})`,
                  backgroundSize: "85% 85%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "110px",
                  paddingTop:'10px'
                }}
                className="img1"
              >
                <Grid container>
                  <Grid xs={6} style={{}}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        // paddingTop: "25px",
                        color: "#cd8264",
                        textAlign:'center'
                      }}
                    >
                      Total Balance
                    </p>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        borderRadius: "5px",
                        width: "60%",
                        padding: "3px 4px",
                        fontSize: "10px",
                        backgroundColor: "#431313",
                        color: "#ffc7b1",
                        margin:'auto',
                        textAlign:'center',
                        marginBottom:'5px'
                      }}
                    >
                      {totalAmount}
                    </p>
                  </Grid>
                  <Grid xs={6} style= {{}}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        // paddingTop: "25px",
                        color: "#cd8264",
                        textAlign:'center',
                        marginBottom:'5px'
                      }}
                    >
                      Withdraw
                    </p>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        borderRadius: "5px",
                        width: "60%",
                        padding: "3px 4px",
                        fontSize: "10px",
                        backgroundColor: "#431313",
                        color: "#ffc7b1",
                        margin:'auto',
                        textAlign:'center',
                        marginBottom:'0px'
                      }}
                      className="type1"
                    >
                      {withdrowable}
                    </p>
                  </Grid>
                  <Grid xs={12} style={{marginTop:'7px'}}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                        paddingTop: "1px",
                        color: "#cd8264",
                        textAlign:'center',
                        marginBottom:'0px'
                      }}
                    >
                      Amount
                    </p>

                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        width: "60%",
                        textAlign: "center",
                        color: "#ffc7b1",
                        margin:'auto',
                        textAlign:'center',
                        marginBottom:'5px'
                      }}
                      className="type1"
                    >
                      <input
                        type="text"
                        placeholder="Enter the Amount"
                        required
                        style={{
                          backgroundColor: "#431313",
                          borderRadius: "5px",
                          color: "#cd8264",
                          textAlign:'center',
                          margin:'auto'
                        }}
                        className="type1"
                        value={withdrawalAmount}
                        onChange={handleAmountChange}
                      />
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundImage: `url(${images[11]})`,
                  backgroundSize: "85% 100%",
                  backgroundRepeat: "no-repeat",
                  paddingTop: "17px",
                  backgroundPosition: "center",
                  marginBottom:'5px',
                  paddingBottom:'0'
                  // height: "120px"
                }}
                className="button2"
               
              >
                <Grid container style={{ height: "20px" }}>
                  <Grid item xs={6}>
                    <p
                      style={{
                        marginTop: "0px",
                        marginBottom: "0px",
                        // borderRadius: "5px",
                        width: "60%",
                        textAlign: "center",
                        color: "#ffc7b1",
                        textAlign:'center',
                        margin:'auto',
                        border:'1px solid black',
                        borderRadius:'10px',
                        backgroundColor: "#431313",
                       minHeight:'20px',
                      //  paddingTop:'17px'
                      }}
                      className="type1"
                    >
                      {displaytype}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container style={{ justifyContent: "space-evenly" }}>
                      <Grid item xs={3}>
                        <label>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "0px",
                              color: "white",
                              marginTop: "0px",
                              paddingTop: "0px"
                            }}
                          >
                            <input
                              type="radio"
                              name="radio-button"
                              value="upi"
                              required
                              checked={selectedOption === "upi"}
                              onChange={handleOptionChange}
                            />
                            <img
                              src={images[9]}
                              width="45px"
                              style={{ marginTop: "10px" }}
                              alt=""
                            />
                          </p>
                        </label>
                      </Grid>

                      <Grid item xs={3}>
                        <label>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "0px",
                              color: "white",
                              marginTop: "0px",
                              paddingTop: "0px"
                            }}
                          >
                            <input
                              type="radio"
                              value="bank"
                              name="radio-button"
                              required
                              checked={selectedOption === "bank"}
                              onChange={handleOptionChange}
                            />
                            <img src={images[10]} width="45px" alt="" />
                          </p>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} style={{textAlign:'center',marginTop:'27px',paddingBottom:'3px'}}>
                  <Button
                    className="button1"
                    type="submit"
                    style={{
                      marginTop: "6px",
                      fontSize: "15px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      padding: "5px 5px",
                      color: "white",
                      backgroundImage: `url(${images[2]})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      padding:'0 12px',
                      marginBottom:'2px'

                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      case obj.Bank:
        return (
          <Container
        
          style={{ backgroundColor:'rgb(91, 34, 34)',height:'100%'}}
          
          >
            <form style={{height:'100%'}} onSubmit={handleBankSubmit}>
              <Grid
                container
                style={{
              
                  paddingTop: "10px",
                  paddingBottom: "5px",
                  width: "100%",
                  height: "100%"
                }}
              >
                <p
                  style={{
                    marginBottom: "10px",
                    marginTop: "0px",
                    paddingBottom: "0px",
                    textAlign: "center",
                    color: "white",
                    width: "100%"
                  }}
                >
                  <p style={{ marginBottom: "0px", marginTop: "0px" }}>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#df8e8e",
                        padding: "0px 4px"
                      }}
                    >
                      Account Number
                    </span>
                    <input
                      color="white"
                      type="text"
                      name="accountNumber"
                      value={formdatabank.accountNumber}
                      onChange={handleChangebank}
                      style={{
                        borderRadius: "10px",
                        padding: "2px 4px",
                        backgroundColor: "#310e0e",
                        color: "white"
                      }}
                    />
                  </p>
                </p>
                <Grid
                  xs={12}
                  style={{
                    backgroundImage: `url(${images[11]})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // height: "120px",
                    textAlign:'center'
                  }}
                  className="bank1"
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            UserName
                            <input
                              color="white"
                              type="text"
                              name="holderNameuser"
                              value={formdatabank.holderNameuser}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                color: "white",
                                padding: "2px 4px",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            IFSC
                            <input
                            className="display-block"
                              type="text"
                              color="white"
                              name="ifscCode"
                              value={formdatabank.ifscCode}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                padding: "2px 4px",
                                color: "white",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            BankName
                            <input
                              color="white"
                              type="text"
                              name="bankName"
                              value={formdatabank.bankName}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                color: "white",
                                padding: "2px 4px",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            marginTop: "10px",
                            marginBottom: "0px",
                            fontSize: "12px",
                            paddingTop: "2px",
                            color: "#cd8264"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "12px",
                              color: "#df8e8e",
                              padding: "0px 4px"
                            }}
                          >
                            Email
                            <input
                            className="display-block"
                              type="text"
                              color="white"
                              name="email"
                              value={formdatabank.email}
                              onChange={handleChangebank}
                              style={{
                                borderRadius: "10px",
                                color: "white",
                                padding: "2px 4px",
                                backgroundColor: "#310e0e"
                              }}
                            />
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Button
                    type="submit"
                    className="button2"
                    style={{
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontSize: "15px",
                      borderRadius: "10px",
                      fontWeight: "bold",
                      padding: "13px",
                      color: "white",
                      backgroundImage: `url(${images[2]})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display:'block',
                      margin:'auto'
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          
          </Container>
        );
      default:
        return null;
    }
  };
 
  return (
    <>
        <Container
          style={{
            width: "100%",
           height:'90%',
            padding:'0',
            borderRadius:'15px',
            position:'relative',
            display:'flex',
            alignItems:'center',
            maxHeight:'350px',
            maxWidth:'560px',
            top:'50%',
            transform:"translateY(-50%)"
          }}
        >
          <Grid container style={{position:'relative',height:'100%'}}  className="withdrow-main-container" >
          <Link>
        <img
          onClick={()=>{onClose();dispatch(handleButtonSound());}}
          style={{
            position: "absolute",
            top: "1px",
            right: "2px",
            color: "white",
            width:'22px'
          }}
          src={images[40]}
        />
         <div onClick={()=>{handleButtonClick('rules');dispatch(handleButtonSound());}} style={{color:'#d5a378',position:'absolute',left:'5%',bottom:'2%',textDecoration:'underline',fontSize:'14px',cursor:'pointer'}}>Rules</div>
      </Link>
            <Grid xs={2.5} style={{ backgroundColor:'rgb(59, 1, 1)',paddingTop:'10px',height:'100%',boxSizing:'border-box'}}>
              {Object.keys(obj)?.map((item) => (
                <ListItem
                  style={{
                    marginTop: "1px",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "rgb(255, 185, 51)",
                    paddingLeft:'0',
                    paddingRight:'0'
                  }} // Set your desired font size, e.g., "16px"
                  key={item}
                  className={`${styless.section} ${
                    activeSectionKey === item ? styless.activee : ""
                  }`}
                  onClick={() => {setActiveSectionKey(item);dispatch(handleButtonSound());}}
                >
                  <span
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    {item}
                  </span>
                </ListItem>
              ))}
            </Grid>
            <Grid xs={9.5}  style={{height:'100%',boxSizing:'border-box'}}>{genratebody1()}</Grid>
          </Grid>
          <ToastContainer />
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                width: "100%",
                boxShadow: "none",
                height: "100%",
                paddingBottom: "0",
                overflow:'hidden'
              }
            }}
          >
            <DialogContent style={{overflow:'hidden'}}>{DialogContental()}</DialogContent>
          </Dialog>
        </Container>
    </>
  );
};

export default Withdraw;
