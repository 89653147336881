// Initial state of the data
import { SET_TOKEN } from "./ActionApiOfData";
import { PLAY_BUTTON_CLICK_SOUND } from './ActionApiOfData';
const initialState = {
  api1Data: [], // Data from API 1
  api2Data: [], // Data from API 2
  api3Data: [], // Data from API 3
  api4Data: [],
  api5Data: [],
  api6Data: [],
  api7Data: [],
  api8Data: [],
  api9Data: [],
  api10Data: [],
  api11Data: [],
  api12Data: [],
  api13Data: [],
  api14Data: [],
  api15Data: [],
  api16Data: [],
  api17Data: [],
  music: [true],
  Lobby: [true],
  sound: [true],
  api18Data: [],// Data from API 4
  userphoneidentity: null,
  error: null // Error message
};




// Data reducer function
export const dataReducer = (state = initialState, action) => {
  // console.log("Reducer action type:", action.type);
  // console.log("Reducer state:", state);
  switch (action.type) {
    case "FETCH_API1_DATA_SUCCESS":
      return {
        ...state,
        api1Data: action.payload, // Update API 1 data
        error: null // Clear error message
      };
    case "FETCH_API1_DATA_FAILURE":
      return {
        ...state,
        api1Data: [], // Clear API 1 data
        error: action.payload // Update error message
      };
    case "FETCH_UPIDETAILS_API1_DATA_SUCCESS":
      return {
        ...state,
        api2Data: action.payload,
        error: null
      };
    case "FETCH_UPIDETAILS_DATA_FAILURE":
      return {
        ...state,
        api2Data: [] // Clear API 1 data
      };
    case "FETCH_BANKDETAILS_API1_DATA_SUCCESS":
      return {
        ...state,
        api3Data: action.payload,
        error: null
      };
    case "FETCH_BANKDETAILS_DATA_FAILURE":
      return {
        ...state,
        api3Data: [] // Clear API 1 data
      };

    case "FETCH_GAMELOBBY_API1_DATA_SUCCESS":
      return {
        ...state,
        api4Data: action.payload,
        error: null
      };
    case "FETCH_GAMELOBBY_API1_DATA_FAILURE":
      return {
        ...state,
        api4Data: [], // Clear API 1 data
        error: null // Update error message
      };
    case SET_TOKEN:
      return {
        ...state,
        userphoneidentity: action.payload
      };

      case "GameData":
        return {
          ...state,
          api5Data: action.payload,
          error: null
        };

        case "GameDataErr":
      return {
        ...state,
        api5Data: [] // Clear API 1 data
      };

      case "BankDetails":
        return {
          ...state,
          api6Data: action.payload,
          error: null
        };

        case "BankDetailsErr":
      return {
        ...state,
        api6Data: [] // Clear API 1 data
      };

      case "UPIDetails":
        return {
          ...state,
          api7Data: action.payload,
          error: null
        };

        case "UPIDetailsErr":
      return {
        ...state,
        api7Data: [] // Clear API 1 data
      };

      case "loadedImges":
        return {
          ...state,
          api8Data: action.payload,
          error: null
        };

        case "sliderImg":
          return {
            ...state,
            api9Data: action.payload,
            error: null
          };
  
          case "sliderImgErr":
        return {
          ...state,
          api9Data: [] // Clear API 1 data
        };
  
        case "NoticeImg":
          return {
            ...state,
            api10Data: action.payload,
            error: null
          };
  
          case "NoticeImgErr":
        return {
          ...state,
          api10Data: [] // Clear API 1 data
        };
  
        case "storeImageDataItems":
          return {
            ...state,
            api11Data: action.payload,
            error: null
          };

          case "FestivlData":
          return {
            ...state,
            api12Data: action.payload,
            error: null
          };
  
          case "FestivlDataErr":
        return {
          ...state,
          api12Data: [] // Clear API 1 data
        };

        case "gamehistory":
          return {
            ...state,
            api13Data: action.payload,
            error: null
          };
  
          case "gamehistoryErr":
        return {
          ...state,
          api13Data: [] // Clear API 1 data
        };

        case PLAY_BUTTON_CLICK_SOUND:
          // Logic for handling the action, if needed
          return state;

          case "ReferWinData":
          return {
            ...state,
            api14Data: action.payload,
            error: null
          };
  
          case "ReferWinDataErr":
        return {
          ...state,
          api14Data: [] // Clear API 1 data
        };

        case "ReferEarnData":
          return {
            ...state,
            api15Data: action.payload,
            error: null
          };
  
          case "ReferEarnDataErr":
        return {
          ...state,
          api15Data: [] // Clear API 1 data
        };
        case "ReferWinMinimum":
          return {
            ...state,
            api16Data: action.payload,
            error: null
          };
  
          case "ReferWinMinimumErr":
        return {
          ...state,
          api16Data: [] // Clear API 1 data
        };

        case "ReferEarnMinimum":
          return {
            ...state,
            api17Data: action.payload,
            error: null
          };
  
          case "ReferEarnMinimumErr":
        return {
          ...state,
          api17Data: [] // Clear API 1 data
        };

        case "MusicControl":
          console.log(action.payload,'action payload data')
          return {
            ...state,
            music: [action.payload],
          };

          case "LobbyLoadingControl":
            console.log(action.payload,'action payload data')
            return {
              ...state,
              Lobby: [action.payload],
            };

            case "SoundControl":
              console.log(action.payload,'action payload data')
              return {
                ...state,
                sound: [action.payload],
              };

              case "RefreeData":
                console.log(action.payload,'action payload data')
                return {
                  ...state,
                  sound: [action.payload],
                };
         
    default:
      return state; // Return current state if action type is not recognized
  }
};