import React, { useState } from "react";
import { Container } from "@mui/material";
import "./Binding.css";

import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, handleButtonSound } from "../../RaectRedux/ActionApiOfData";

const Binding = ({ onClose }) => {
  const navigate = useNavigate();
  const [userphone, setUserphone] = useState(""); // State for username
  const [userotp, setUserotp] = useState(""); // State for email
  const userDataId = Cookies.get("token");
  const getIdData = jwtDecode(userDataId);
  const dispatch = useDispatch();
  const actualId = getIdData._id || 0;

  const images = useSelector((state)=>state.add.api8Data);

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = Math.floor(Math.random() * 3) + 8; // Random length between 6 to 8
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }
  const SendOtpFun = async (otpdata, userphonedata) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}send-otp`, {
        userphone: userphonedata,
        userotp: otpdata
      });

      if (response.data) {
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  const SendOtp = async () => {
    const otp = Math.floor(1000 + Math.random() * 9000);
  
    try {
      const response = await axios.post(
       ` ${process.env.REACT_APP_BASE_URL}validate-binding`,
        {
          userphone,
          otp,
          actualId
        }
      );
      if (
        response.data &&
        response.data.message === "Phone number already exists"
      ) {
        toast.error("Number alreday exist", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else if (
        response.data &&
        response.data.message === "User updated successfully"
      ) {
        SendOtpFun(otp, userphone);
        // PhoneSubmit(otp);
      } else {
        toast("Network Error", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const refralcode = generateRandomString();
    const username = "rocky";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}BindingData`,
        { userotp, userphone, refralcode, actualId, username },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (response.data && response.data.msg === "Update Successfully") {
        // alert("User not found");
        toast("Otp Update", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1);
        Cookies.set("token", response.data.token, {
          expires: expirationDate,
          path: "/"
        });
        const getdat = Cookies.get("token");
        const readdata = jwtDecode(getdat);
        dispatch(fetchData(readdata._id));
      } else {
        toast.error("Invalid Otp", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      // alert("An error occurred while updating the user.");
      toast.error("Network issue", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const getIsFormValid = () => {
    return userphone.length > 9 && userotp.length > 3;
  };
  const getIsFormOtp = () => {
    return userphone.length > 9;
  };
  return (
    <Container
      style={{
       backgroundImage:`url(${images[62]})`,
       backgroundRepeat:"no-repeat",
       backgroundPosition:'center',
       backgroundSize:"100% 100%",
        padding: "5px",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        borderRadius: "15px",
        height:"68%",
        maxHeight:'300px',
        maxWidth:'480px',
        paddingTop:"6%"
      }}
    >
      <p className="binding-p1">Binding Phone No</p>

      <div>
        <label className="binding-label" htmlFor="phone">
          Phone Number:
        </label>
        <input
          type="text"
          style={{
            padding: "6px 0px",
            color: "ActiveBorder",
            fontSize: "10px"
          }}
          id="phone"
          value={userphone}
          onChange={(e) => {
            setUserphone(e.target.value);
          }}
          placeholder="please enter your mobile number"
        />
      </div>
      <div>
        <label className="binding-label" htmlFor="OTP">
          OTP:
        </label>
        <input
          style={{
            padding: "6px 0px",
            color: "ActiveBorder",
            fontSize: "10px"
          }}
          type="text"
          id="OTP"
          value={userotp}
          onChange={(e) => {
            setUserotp(e.target.value);
          }}
          placeholder="verification code"
        />
        <button
          className={getIsFormOtp() ? "validotp" : "invalidotp"}
          disabled={!getIsFormOtp()}
          onClick={()=>{SendOtp();dispatch(handleButtonSound());}}
        >
          OTP
        </button>
      </div>
      <button
        className={getIsFormValid() ? "valid" : "invalid"}
        disabled={!getIsFormValid()}
        onClick={(e)=>{handleSubmit(e);dispatch(handleButtonSound());}}
      >
        Confirm
      </button>

      <Link to="">
        <img
          onClick={()=>{onClose();dispatch(handleButtonSound());}}
          style={{
            position: "absolute",
            top: "-1%",
            right: "-2%",
            color: "white",
            width: "27px"
          }}
          alt=""
          src={images[40]}
        />
      </Link>
      <ToastContainer />
    </Container>
  );
};

export default Binding